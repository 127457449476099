export default [
	{
		icon: "bi-filetype-txt",
		name: "txt",
		contentType: "text/plain"
	},
	{
		icon: "bi-filetype-pdf",
		name: "pdf",
		contentType: "application/pdf"
	},
	{
		icon: "bi-filetype-docx",
		name: "docx",
		contentType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
	},
	{
		icon: "bi-filetype-doc",
		name: "doc",
		contentType: "application/msword"
	},
	{
		icon: "bi-filetype-xls",
		name: "xls",
		contentType: "application/vnd.ms-excel"
	},
	{
		icon: "bi-filetype-xlsx",
		name: "xlsx",
		contentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
	},
];
