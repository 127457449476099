<template>
	<div class="kaveh-popup">
		<div class="popup-backdrop" @click="closePopup"></div>
		<div class="popup-content">
			<div class="popup-body" v-bind:class="{'p-0': noPadding}">
				<slot></slot>
				<div style="height:30px;"></div>
			</div>
		</div>
	</div>
</template>
<script>

	export default {
		name: 'Popup',
		props: {
			name: {
				type: String,
				default: ''
			},

			color: {
				type: String,
				default: 'inherit'
			},

			close: {
				type: Function,
			},

			confirmWhenClose: {
				type: Boolean,
				default: false,
			},

			noPadding: {
				type: Boolean,
				default: false,
			}
		},

		data() {
			return {
				isShow: true
			}
		},

		methods: {

			closePopup() {

				const fn = () => {
					if (typeof (this.close) == 'function') {
						return this.close();
					}

					this.isShow = false;
				}

				if (this.confirmWhenClose == true) {
					if (confirm(this.$loc.fromResource('com.popup.closeConfirm'))) {
						fn();
					}
				}
				else {
					fn();
				}
			}
		}
	}
</script>

<style scoped>

	.kaveh-popup,
	.kaveh-popup .popup-backdrop {
		width: 100%;
		height: 100%;
	}


	.kaveh-popup {
		bottom: 0;
		left: 0;
		z-index: 10;
		position: fixed;
	}

		.kaveh-popup .popup-backdrop {
			z-index: 11;
			position: fixed;
			background: var(--askme-color-2);
			opacity: 0.4;
		}

		.kaveh-popup .popup-content {
			width: 100%;
			float: left;
			overflow-y: hidden;
			background: var(--askme-color-1);
			position: absolute;
			left: 0;
			bottom: 0;
			z-index: 12;
			animation: kavehPopupAnimation 0.5s linear 0s 1 normal forwards;
			border-radius: 30px 30px 0 0;
		}

		.kaveh-popup .popup-body {
			width: 100%;
			padding: 10px;
			padding:30px 20px;
			overflow-y:auto;
			float:left;
		}


	@keyframes kavehPopupAnimation {
		0% {
			opacity: 0;
			transform: translateY(100%);
		}

		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}
</style>
