export default {

	isUnicode: (value) =>
	{
		const rtlCharPattern = /[\u0590-\u05FF\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB1D-\uFDFD\uFE70-\uFEFF]/;
		let rtlCharCount = 0;
		const textLength = (value || "").length;
		const threshold = 0.3;

		for (let i = 0; i < textLength; i++)
		{
			if (rtlCharPattern.test(value.charAt(i)))
			{
				rtlCharCount++;
			}
		}

		return (rtlCharCount / textLength) > threshold;
	},


	isRtlText: (value) =>
	{
		const rtlCharPattern = /[\u0590-\u05FF\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB1D-\uFDFD\uFE70-\uFEFF]/;
		let rtlCharCount = 0;
		const textLength = (value || "").length;
		const threshold = 0.3;

		for (let i = 0; i < textLength; i++)
		{
			if (rtlCharPattern.test(value.charAt(i)))
			{
				rtlCharCount++;
			}
		}

		return (rtlCharCount / textLength) > threshold;
	}
}
