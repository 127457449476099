const fa = {

	service: {
		hub: "زیرساخت",
		shop: "فروشگاه آنلاین",
		cms: "مدیرت محتوا",
		guard: "نگهبانان",
		cpay: "پرداخت اعتباری",
		aut: "اتوماسیون",
		post: "مرسولات",
		nem: "تجهیزات شبکه",
		messenger: "پیام رسان",
		asset: "فایل ها",
		cms:"سیستم مدیریت محتوا"
	},

	global: {
		applicationName: 'نرم افزار کاوه',
		signIn: 'ورود به سیستم',
		logOut: 'خروج از سیستم',
		save: 'ذخیره اطلاعات',
		ApplyFilters: 'اعمال فیلترها',
		excelKey: 'کلید اکسل',
		operations: 'عملگرها',
		mathOperators: 'عملگرهای ریاضی',
		RelOperators: 'عملگرهای رابطه‌ای',
		logicOperators: 'عملگرهای منطقی',
		copyright: 'طراحی و تولید توسط  گروه نرم افزاری کاوه',
		filters: "فیلترها",
		tools: 'ابزارها',
		yes: "بله",
		no: "خیر",
		row: 'ردیف',
		name: 'نام',
	},

	languages: {
		fa: "فارسی",
		en: "انگلیسی",
		de: "آلمانی",
	},

	system: {
		pleaseWait: 'لطفا کمی صبر کنید ...',
		pleaseWaite: 'لطفا کمی صبر کنید ...',
		downloading: 'سیستم درحال دریافت اطلاعات است، لطفا منتظر بمانید.',
		uploading: 'سیستم درحال ارسال اطلاعات است، لطفا منتظر بمانید.',
		progressing: 'سیستم درحال انجام عملیات است، لطفا منتظر بمانید.',
		notfound: 'اطلاعاتی جهت نمایش یافت نشد!',
		dataError: 'دریافت اطلاعات با خطا مواجه شد! لطفا با پشتیبان سیستم تماس حاصل فرمایید.',
		wellcome: "به سیستم یکپارچه سازمانی کاوه خوش آمدید. </br> این سیستم با تکنولوژی وب سایت پیشرونده تولید شده است و امکان نصب بر روی انواع سیستم عامل ها را دارد."
	},

	layout: {
		default: 'قالب پیشفرض',
		minimal: 'قالب مینیمال'
	},

	router: {
		back: 'بازگشت',
	},

	status: {
		errorLen: 'تعداد خطا',
		successLen: 'تعداد موفق',
		errorShort: 'عملیات ناموفق',
		successShort: 'عملیات موفق',
		error: 'عملیات با خطا مواجه شد.',
		ok: 'عملیات با موفقیت انجام شد.',
		notFound: 'مقداری یافت نشد  -- {message}',
		unknown: 'خطای داخلی در سرور! لطفا با پشتیبان سیستم تماس حاصل فرمایید.  {message}',
		unavailable: "سرویس در دسترس نیست",
		internal: "خطای داخلی {message}",
		unauthorized: "شما به این بخش دسترسی ندارید و یا جلسه کاری شما پایان یافته است، لطفا دوباره وارد سیستم شوید.",
		unauthenticated: "جلسه کاری شما پایان یافته است، لطفا دوباره وارد سیستم شوید.",
		invalidArgument: 'اطلاعات وارد شده صحیح نیست.  {message}',
		alreadyExists: 'اطلاعات وارد شده تکراری است.  {message}',
		permissionDenied: 'مجوز دسترسی نامعتبر است.  {message}',
		resourceExhausted: "{message}"
	},

	message: {
		attachmentImageAllert: 'این صفحه برای آپلود تصاویر عمومی است، هیچ فایل محرمانه ای را در این بخش آپلود نکنید.',
		deleteRelationError: "به علت وجود رابطه با بخش های دیگر سیستم امکان حذف وجود ندارد",
		invalidPrimaryKey: 'شناسه نامعتبر است',
		networkError: 'خطا در برقرای ارتباط با سرور!',
		serverError: 'خطای داخلی در سرور! لطفا با پشتیبان سیستم تماس حاصل فرمایید.',
		noResponse: 'سرور در دسترس نیست! هیچ پاسخی برای درخواست دریافت نشد.',
		badRequest: 'درخواست نامعتبر است!',
		unavailableService: "سرویس در دسترس نیست",
		emptyResult: 'مقداری جهت نمایش یافت نشد',
		permissionDenied: "شما به این بخش دسترسی ندارید، جهت تکمیل سطوح دسترسی خود با مدیر سیستم هماهنگ بفرمایید.",
		unauthorized: "شما به این بخش دسترسی ندارید و یا جلسه کاری شما پایان یافته است، لطفا دوباره وارد سیستم شوید.",
		loginError: 'نام کاربری یا کلمه عبور اشتباه است',
		deleteConfirm: 'آیا از حذف اطمینان دارید؟',
		confirm: 'آیا اطمینان دارید؟',
		confirmSyncDistributeDatabase: 'شما درحال همگام سازی اطلاعات مشترک بین دیتابیس های توزیع شده سیستم هستید! همگام سازی همیشه به صورت خودکار انجام میشود. در صورتی که خطایی در سیستم توزیع شده وجود دارد این عملیات را ادامه دهید. عملیات ممکن است کمی زمان بر باشد، آیا نسبت به ادامه عملیات اطمینان دارید؟'
	},


	action: {
		create: 'افزودن',
		save: 'ذخیره اطلاعات',
		responce: 'پاسخ',
		enter: "ورود",
		exit: "خروج",
		sendResponce: 'ارسال پاسخ',
		createNewNode: 'افزودن شاخه جدید',
		update: 'ویرایش',
		fileAttachment: 'پیوست فایل',
		imageAttachment: 'پیوست تصویر',
		download: 'دانلود',
		uploadSelectedFiles: 'آپلود فایل های انتخاب شده',
		filter: 'فیلتر',
		import: 'ورود اطلاعات',
		createImport: 'ورود اطلاعات',
		export: 'خروج اطلاعات',
		exportExcel: 'خروج به صورت اکسل',
		importFromExcel: 'ورود از طریق اکسل',
		ApplyFilters: 'اعمال فیلترها',
		history: 'مشاهده تاریخچه',
		importData: 'ورود اطلاعات',
		delete: 'حذف',
		deleteData: 'حذف اطلاعات',
		back: 'بازگشت',
		close: 'بستن',
		search: 'جستجو',
		searchPoint: 'جستجو ...',
		more: 'بیشتر',
		applyFilter: 'اعمال فیلتر',
		toggleFilter: 'بستن/بازکردن فیلتر',
		showList: 'مشاهده لیست',
		submitChange: 'اعمال تغییرات',
		pleaseSelect: 'لطفا انتخاب کنید',
		downloadReport: "دریافت گزارش",
		neverMind: 'بیخیال',
		prev: "قبل",
		next: "ادامه"
	},

	page: {
		kaveh: 'نرم افزار کاوه',
		home: 'خانه',
		profile: 'پروفایل',
		changePass: 'تغییر کلمه عبور',
		notFound: 'صفحه ای یافت نشد',
		cpanel: 'نرم افزار کاوه',
		baseInformation: 'اطلاعات پایه سیستم',
		auth: 'ورود به سیستم',
		dashboard: 'داشبورد',
		baseInfo: "مدیریت اطلاعات پایه",
		baseSys: "اطلاعات سیستمی",
		service: 'سرویس ها',
		cycles: 'چرخه ها',
		accessDenied: 'عدم دسترسی',
		print: 'دریافت پرینت'
	},

	error: {
		checkForm: 'لطفا ابتدا خطاهای فرم را بررسی  و رفع نمایید.',
		required: 'فیلد {label} اجباری است',
		invalid: 'فیلد {label} نامعتبر است',
		digit: 'فیلد {label} باید از نوع عدد بدون 0 اولیه باشد',
		number: 'فیلد {label} باید از نوع عدد باشد',
		stringLength: 'فیلد {label} از حداکثر تعداد مجاز کاراکتر بیشتر وارد شده است',
		noUnicodeString: 'فقط کاراکترهای و اعداد انگلیسی مجاز است',
		byteOutOfRange: 'بیشترین مقدار مجاز برای این فیلد 255 است',
		int16OutOfRange: 'بیشترین مقدار مجاز برای این فیلد 32,767 است',
		uint16OutOfRange: 'بیشترین مقدار مجاز برای این فیلد 65,535 است',
		int32OutOfRange: 'بیشترین مقدار مجاز برای این فیلد 2,147,483,647 است',
		uint32OutOfRange: 'بیشترین مقدار مجاز برای این فیلد 4,294,967,295 است',
		int64OutOfRange: 'بیشترین مقدار مجاز برای این فیلد 9,223,372,036,854,775,807 است',
		uint64OutOfRange: 'بیشترین مقدار مجاز برای این فیلد 18,446,744,073,709,551,615 است',
		password: 'حداقل طول کلمه عبور باید 8 کاراکتر باشد'
	},

	title: {
		attachmentsList: 'لیست پیوست ها',
	},

	ai: {
		"room": {
			"create": "ایجاد گفتگوی جدید",
			"filter": "جستجوی گفتگو ... ",
			"delete": "حذف گفتگو",
			"createTitle": "شخص و یا اشخاص مورد نظر خود را جهت آغاز گفتگو انخاب نمایید.",
			"errors": {
				"error1": "لطفا عنوان گروه را وارد نمایید"
			}
		},
		"chat": {
			"delete": "حذف پیام",
			"deleteForAll": "حذف پیام برای همه",
			"reply": "پاسخ",
			"copy": "کپی متن پیام",
			"showOldChats": "مشاهده پیام های قبل"
		}
	},

	data: {
		uniquekey: 'کلید یکتا',
		track: {
			self: 'اطلاعات ثبت',
			creatorId: 'شناسه ایجاد کننده',
			creatorIp: 'ip ایجاد کننده',
			creatorName: 'نام ایجاد کننده',
			createDate: 'تاریخ ایجاد/میلادی',
			createUtcDate: 'تاریخ ایجاد/جهانی',
			localCreateDate: 'تاریخ ایجاد/شمسی',
			createTime: 'زمان ایجاد',
			createUtcTime: 'زمان ایجاد/جهانی',
			modifierId: 'شناسه ویرایش کننده',
			modifierIp: 'ip ویرایش کننده',
			modifierName: 'نام ویرایش کننده',
			modifyDate: 'تاریخ ویرایش/میلادی',
			modifyUtcDate: 'تاریخ ویرایش/جهانی',
			localModifyDate: 'تاریخ ویرایش/شمسی',
			modifyTime: 'زمان ویرایش',
			modifyUtcTime: 'زمان ویرایش/جهانی',
			summary: 'توسط {creatorName} در {localCreateDate}'
		},

		userAgent: {
			self: 'اطلاعات کاربری',
			userName: 'نام کاربری',
			password: 'کلمه عبور',
		},

		history: {
			self: 'تاریخچه',
			entityValue: 'تغییرات',
			historyName: 'نوع ثبت',
			fullName: 'نام کاربر',
			id: 'شناسه کاربر',
			ip: 'ایپی کاربر',
		},

		historyTime: {
			self: 'مشخصات زمانی',
			createDate: 'تاریخ/میلادی',
			createPersianDate: 'تاریخ/شمسی',
			createTime: 'ساعت',
		},

		baseEntityForm: "اطلاعات فرم"
	},

	com: {
		excel: {
			chooseFile: 'انتخاب فایل',
			backToSelectFile: 'بازگشت',
			review: 'بررسی مجدد',
			reviewLength: 'تعداد بررسی ها',
			NumberOfReviewsPerformed: 'تعداد بررسی های انجام شده',
			reviewMessage: 'در صورتی که بعد از بررسی مجدد هیچ خطایی در اطلاعات وجود نداشته باشد سیستم وارد بخش ورود اطلاعات میشود.',
			fileIsEmpty: 'فایل انتخاب شده فاقد دیتا است!',
			downloadSampleFile: 'دریافت فایل خام',
			fileProcessing: 'سیستم در حال پردازش فایل است، لطفا منتظر باشید.',
			fileHasError: `<strong class="text-danger d-block mb-2">فایل دارای اطلاعات نامعتبر است</strong>
					<p>از طریق جدول زیر میتوانید بدون نیاز به ویرایش فایل اکسل، تمامی اطلاعات نامعتبر و دارای خطا را تصحیح نمایید.</br> شماره رکورد خطا ها به شرح زیر است :</p>`,

			warning: `<p>جهت ورود اطلاعات از طریق فایل اکسل به نکات زیر دقت فرمایید.</p>
				<ul>
					<li>ابتدا فایل خام را دانلود نمایید.</li>
					<li>دیتای مورد نظر خود را درون فایل خام وارد نمایید.</li>
					<li>فیلد های الزامی با علات ستاره (*) مشخص شده اند.</li>
					<li>رکورد شماره یک فایل خام را تغییر ندهید.</li>
					<li>فایل خام ممکن است بیش از یک شیت داشته باشد که اطلاعات هر قسمت درون شیت مخصوص خود قرار میگیرد.</li>
				</ul>`,

			sampleFileName: 'فایل نمونه {sheetName} - {id}',
		},

		table: {
			filterResult: 'فیلتر بر روی {totalfilters} ردیف انجام شد.',
			pageInfo: 'صفحه {currentPage} از {totalPages}',
			action: {
				exportCurentPageAsExcel: 'دانلود صفحه فعلی به صورت اکسل',
				createReport: 'ساخت گزارش'
			},
			headerSearch: 'جستجو در {label}',
			searchPlaceholder: 'مقدار مورد نظر خود را وارد نمایید و سپس دکمه Enter را فشار دهید',
			report: {
				progressing: "سیستم در حال ساخت و دانلود گزارش است، لطفا کمی صبر کنید ...",
				done: "عملیات با موفقیت انجام شد، فایل به صورت خودکار دانلود خواهد شد."
			}
		},

		task: {
			repeat: 'تکرار دوباره',
			skip: 'رد شدن',
			replace: 'جایگزین کردن',
			cancel: 'لغو کامل عملیات',
			doTasks: 'انجام عملیات',
			cancelTasks: 'لغو عملیات',
			errorContent: 'محتوای خطا',
			taskRowNumberError: 'رکورد شماره {index} با خطا مواجه شد.',
			tasksFailedLenght: 'تعداد رکوردهای ناموفق',
			tasksSucceedLenght: 'تعداد رکوردهای موفق',
			applyToAllCases: 'این انتخاب برای تمامی موارد بعدی با خطای مشابه تکرار شود',
			progressing: 'سیستم درحال انجام عملیات است، لطفا منتظر بمانید.',
			tasksCompletedWithError: 'عملیات همراه با خطا به پایان رسید.',
			tasksCompletedSuccess: 'عملیات با موفقیت به پایان رسید.',
			excelRowIndex: 'شماره ردیف فایل اکسل',
			errorDetail: 'شماره ردیف فایل اکسل',
			warning: `<strong>اطلاعات زیر جهت {label} انتخاب شده است.</strong>
				<p>درصورتی که از انجام عملیات فوق اطمینان دارید بر روی دکمه انجام عملیات کلیک نمایید.</p>`,
		},

		uploader: {
			chooseMessage: "فایل خود را با این قسمت بکشید و رها کنید و یا روی دکمه انتخاب فایل کلیک نمایید.",
			chooseButton: "انتخاب فایل",
			addFile: "افزودن فایل جدید",
			count: "تعداد {label} فایل انتخاب شده است",
			allowExtensions: "فرمت های قابل قبول",
			errors: {
				invalidType: "فرمت فایل {label} نامعتبر است",
				duplicate: "فایل {label} قبلا انتخاب شده است",
				longName: "حداکثر طول مجاز نام فایل 50 نویسه است"
			}
		},

		common: {
			cd: {
				warning: `<strong>کاربر گرامی شما در حال انجام عملیات حذف هستید!</strong>
				<p>درصورتی که از انجام عملیات فوق اطمینان دارید بر روی دکمه حذف اطلاعات کلیک نمایید.</p>`,
				ok: 'حذف اطلاعات',
				cancel: 'لغو و بازگشت',
			}
		},

		form: {
			checkbox: {
				yes: 'بله',
				no: 'خیر'
			},
			title: {
				require: "فیلد '{label}' اجباری است",
				unique: "فیلد '{label}' یکتا است",
				requireUnique: "فیلد '{label}' اجباری و یکتا است"
			},

			generator: {
				entityName: "فرم پیشفرض",
				head: {
					controls: "فیلد ها",
					preview: "پیشنمایش",
					config: "تنظیمات",
					patterns: "الگوها"
				},
				controls: {
					text: "فیلد متن",
					number: "فیلد عدد",
					textarea: "باکس متن",
					password: "فیلد امنیتی",
					select: "فیلد چند انتخابی",
					checkdrop: "فیلد بله-خیر",
					color: "فیلد رنگ",
					datePicker: "فیلد تاریخ",
					timePicker: "فیلد ساعت",
					signaturePad: "امضای دیجیتال",
					textEditor: "ویرایشگر متن",
					hr: "خط جدا کننده",
					h3: "عنوان",
					lable: "متن راهنما",
					removeConfirm: 'آیا از حذف اطمینان دارید؟',
					defaultValue: "مقدار پیش فرض"
				},


				config: {
					label: "عنوان",
					isUnicode: "امکان تایپ فارسی",
					select: {
						head: "انتخاب ها",
						text: "عنوان",
						value: "مقدار",
						textNumber: "انتخاب شماره {label}",
						serverAddress: "آدرس سرور"
					},
					patterns: {
						required: "فیلد اجباری است",
						mobile: "الگوی شماره موبایل",
						nationalCode: "الگوی کد ملی",
						email: "الگوی ایمیل",
					},
				}
			},

			select: {
				noOption: "مقداری جهت نمایش یافت نشد."
			},
		},

		textEditor: {
			table: {
				rowCount: 'تعداد سطر',
				colCount: 'تعداد ستون',
				create: 'ایجاد جدول'
			}
		},

		camera: {
			open: 'اتصال به دوربین',
			close: 'قطع اتصال دوربین',
			take: 'ثبت تصویر'
		},

		popup: {
			closeConfirm: 'آیا از بسته شدن اطمینان دارید؟'
		},
	}
}

export default fa
